.margin {
  margin-top: 20px;
}

.features {
  margin-top: 40px;
}

pre {
  background-color: #ecf0f1;
  padding: 15px;
  border: 1px solid #cccccc;
  display: block;
  font-size: 14px;
  border-radius: 4px;
  word-wrap: break-word;
  word-break: break-all;
}
